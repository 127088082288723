__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import $ from "jquery";
import Swiper from 'swiper';
import {Virtual, FreeMode, Mousewheel, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';





document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();


  document.fonts.ready.then(function() {
    console.log('js works');

    var ww = $(window).width();


    $("main").scroll(function () {
      if($('.page-title').offset().top == 0){
        $("header").addClass("align-right");
      }else if($("header").hasClass("align-right")){
        $("header").removeClass("align-right");
      }
    });


    /*$('.collapse-item a[data-toggle="collapse"]').click(function () {

        if ($(this).hasClass("collapsed")){
            $(this).addClass("td-undl");
        }else{
            $(this).removeClass("td-undl");
        }
    });*/

    var mySwiper = new Swiper('.swiper-container', {
      // Optional parameters
      modules: [Virtual, Navigation, Pagination, Mousewheel, FreeMode],
      slidesPerView: 2,
      direction: "vertical",
      init: false,
      zoom: {
        maxRatio: 1,
      },
      preloadImages: false,
      lazyPreloadPrevNext: 3,
      loop: true,
      simulateTouch: true,
      allowTouchMove: true,
      freeMode: false,
      freeModeSticky: true,
      slidesPerGroup: 2,
      effect: "slide",
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      speed: 300,
      breakpoints: {
        // when window width is >= 320px
        768: {
          direction: "horizontal",
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
          speed: 1,
          slidesPerGroup: 1,
        },
      },
    });

    mySwiper.init();


    function windowResize() {
      ww = $(window).width();

      if(ww > 768){
        mySwiper.mousewheel.disable()
      }else{
        mySwiper.mousewheel.enable();
      }
    }

    windowResize();

    $(window).resize(function () {
      windowResize();
    });

    var vid = document.getElementById("myvideo");
    vid.play();






  });





});
